import React from "react";
import { CompanyManagerStoreProvider } from "./stores/CompanyManagerStore";
import Layout from "./Layout";
import { ClerkProvider } from "@clerk/clerk-react";
import { useState } from "react";
if (!import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  const [redirect, setRedirect] = useState("/profile");
  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => console.log(to)}
      afterSignInUrl={redirect}
      afterSignUpUrl={redirect}
    >
      <CompanyManagerStoreProvider>
        <Layout redirect={redirect} setRedirect={setRedirect} />
      </CompanyManagerStoreProvider>
    </ClerkProvider>
  );
}

export default App;
